var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1", attrs: { "no-body": "" } }, [
        _c("div", { staticClass: "m-2" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-1" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.id,
                      expression: "filters.id",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "search", placeholder: "ID" },
                  domProps: { value: _vm.filters.id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "id", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.name,
                      expression: "filters.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "search", placeholder: "Código" },
                  domProps: { value: _vm.filters.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "name", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "v-select",
                    {
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Curso",
                        options: _vm.optionsCourses,
                        clearable: true,
                      },
                      on: { search: _vm.fetchCourse },
                      model: {
                        value: _vm.filters.course,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "course", $$v)
                        },
                        expression: "filters.course",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v("Digite o título do curso")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      placeholder: "Situação",
                      options: [
                        { code: 1, title: "Ativo" },
                        { code: 0, title: "Inativo" },
                      ],
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      options: _vm.optionsModalities,
                      clearable: true,
                      searchable: false,
                      placeholder: "Modalidade",
                    },
                    model: {
                      value: _vm.filters.modality,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "modality", $$v)
                      },
                      expression: "filters.modality",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "v-select",
                    {
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Curso Venda",
                        options: _vm.optionsCourseSales,
                        clearable: true,
                      },
                      on: { search: _vm.fetchCourseSale },
                      model: {
                        value: _vm.filters.course_sale,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "course_sale", $$v)
                        },
                        expression: "filters.course_sale",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v("Digite o título do curso venda")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      placeholder: "Unidade",
                      options: _vm.optionsUnits,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "unit", $$v)
                      },
                      expression: "filters.unit",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      placeholder: "Turno",
                      options: _vm.optionsShifts,
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.shift,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "shift", $$v)
                      },
                      expression: "filters.shift",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-5" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Período de início",
                      config: {
                        mode: "range",
                        altInput: true,
                        dateFormat: "Y-m-d",
                        altFormat: "d/m/Y",
                        locale: "pt",
                      },
                    },
                    model: {
                      value: _vm.filters.rangeDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "rangeDate", $$v)
                      },
                      expression: "filters.rangeDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-5" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      placeholder: "Painel de vendas",
                      options: [
                        { code: 1, title: "Sim" },
                        { code: 0, title: "Não" },
                      ],
                      clearable: true,
                      searchable: false,
                    },
                    model: {
                      value: _vm.filters.display_panel,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "display_panel", $$v)
                      },
                      expression: "filters.display_panel",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-2 text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  staticStyle: { padding: "14px !important" },
                  on: { click: _vm.search },
                },
                [
                  !_vm.btnSearch
                    ? _c("i", { staticClass: "bi bi-search" })
                    : _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "col-md-2 my-1" },
            [
              _c("v-select", {
                attrs: {
                  label: "title",
                  options: _vm.perPageOptions,
                  clearable: false,
                  searchable: false,
                },
                on: { input: _vm.setPerPageSelected },
                model: {
                  value: _vm.perPage,
                  callback: function ($$v) {
                    _vm.perPage = $$v
                  },
                  expression: "perPage",
                },
              }),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    item.confirmed
                      ? _c("i", { staticClass: "bi bi-check-all text-success" })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item.course_title) + " "),
                  ]
                },
              },
              {
                key: "cell(start_time)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("justTime")(item.start_time)) +
                        " às " +
                        _vm._s(_vm._f("justTime")(item.end_time)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(modality_name)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: {
                          variant:
                            "light-" +
                            (item.modality_name === "Presencial"
                              ? "primary"
                              : "warning"),
                        },
                      },
                      [_vm._v(_vm._s(item.modality_name))]
                    ),
                  ]
                },
              },
              {
                key: "cell(customers)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value: "Matrículas/Limite",
                            expression: "`Matrículas/Limite`",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.customers) +
                            "/" +
                            _vm._s(item.max_students ? item.max_students : 0) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(postponement)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("datePT")(item.postponement, false)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: {
                          variant:
                            item.status === 1
                              ? "light-success"
                              : "light-danger",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.status === 1 ? "Ativo" : "Inativo") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.$can("Turma - Excluir", "Pedagógico") ||
                    _vm.$can("Turma - Editar", "Pedagógico")
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex-between",
                            staticStyle: { gap: "15px" },
                          },
                          [
                            _vm.$can("Turma - Excluir", "Pedagógico")
                              ? _c("feather-icon", {
                                  staticClass:
                                    "cursor-pointer cursor text-danger",
                                  attrs: { icon: "TrashIcon", size: "16" },
                                  on: {
                                    click: function ($event) {
                                      item.grid_id
                                        ? _vm.deleteIsBlocked(item.grid_id)
                                        : _vm.confirmDelete(item.uuid)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.$can("Turma - Editar", "Pedagógico")
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "classrooms-edit",
                                        params: { uuid: item.uuid },
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "cursor-pointer cursor",
                                      attrs: { icon: "EditIcon", size: "16" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              rowsTable: _vm.rowsTable,
              currentPage: _vm.currentPage,
              perPage: _vm.perPage,
              totalRows: _vm.totalRows,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }