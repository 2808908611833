<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="form-row">
          <div class="col-md-1">
            <div class="form-group">
              <input
                type="search"
                class="form-control"
                placeholder="ID"
                v-model="filters.id"
              />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <input
                type="search"
                class="form-control"
                placeholder="Código"
                v-model="filters.name"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.course"
                placeholder="Curso"
                :options="optionsCourses"
                @search="fetchCourse"
                :clearable="true"
              >
                <span slot="no-options">Digite o título do curso</span>
              </v-select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <v-select
                label="title"
                placeholder="Situação"
                v-model="filters.status"
                :options="[
                  { code: 1, title: 'Ativo' },
                  { code: 0, title: 'Inativo' },
                ]"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <v-select
                label="title"
                v-model="filters.modality"
                :options="optionsModalities"
                :clearable="true"
                :searchable="false"
                placeholder="Modalidade"
              />
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-4">
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.course_sale"
                placeholder="Curso Venda"
                :options="optionsCourseSales"
                @search="fetchCourseSale"
                :clearable="true"
              >
                <span slot="no-options">Digite o título do curso venda</span>
              </v-select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <v-select
                label="title"
                placeholder="Unidade"
                v-model="filters.unit"
                :options="optionsUnits"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <v-select
                label="title"
                placeholder="Turno"
                v-model="filters.shift"
                :options="optionsShifts"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-5">
            <div class="form-group">
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                placeholder="Período de início"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <v-select
                label="title"
                placeholder="Painel de vendas"
                v-model="filters.display_panel"
                :options="[
                  { code: 1, title: 'Sim' },
                  { code: 0, title: 'Não' },
                ]"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>
          <div class="col-md-2 text-right">
            <button
              @click="search"
              class="btn btn-primary btn-block"
              style="padding: 14px !important"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="col-md-2 my-1">
        <v-select
          label="title"
          v-model="perPage"
          :options="perPageOptions"
          @input="setPerPageSelected"
          :clearable="false"
          :searchable="false"
        />
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(title)="{ item }">
          <i v-if="item.confirmed" class="bi bi-check-all text-success"></i> 
          {{ item.course_title }}
        </template>

        <template #cell(start_time)="{ item }">
          {{ item.start_time | justTime }} às {{ item.end_time | justTime }}
        </template>

        <template #cell(modality_name)="{ item }">
          <b-badge
            class="d-block"
            :variant="`light-${
              item.modality_name === 'Presencial' ? 'primary' : 'warning'
            }`"
            >{{ item.modality_name }}</b-badge
          >
        </template>

        <template #cell(customers)="{ item }">
          <span v-b-tooltip.hover.top="`Matrículas/Limite`">
            {{ item.customers }}/{{ item.max_students ? item.max_students : 0 }}
          </span>
        </template>

        <template #cell(postponement)="{ item }">
          {{ item.postponement | datePT(false) }}
        </template>

        <template #cell(status)="{ item }">
          <b-badge
            class="d-block"
            :variant="item.status === 1 ? 'light-success' : 'light-danger'"
          >
            {{ item.status === 1 ? "Ativo" : "Inativo" }}
          </b-badge>
        </template>

        <template #cell(actions)="{ item }">
          <div
            v-if="
              $can('Turma - Excluir', 'Pedagógico') ||
              $can('Turma - Editar', 'Pedagógico')
            "
            class="d-flex-between"
            style="gap: 15px"
          >
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="
                item.grid_id
                  ? deleteIsBlocked(item.grid_id)
                  : confirmDelete(item.uuid)
              "
              v-if="$can('Turma - Excluir', 'Pedagógico')"
            />
            <router-link v-if="$can('Turma - Editar', 'Pedagógico')" :to="{
              name: 'classrooms-edit',
              params: { uuid: item.uuid },
            }">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="currentPage"
        :perPage="perPage"
        :totalRows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  VBTooltip,
  BFormRow,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  components: {
    BFormInput,
    CustomPaginateTable,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormRow,
    flatPickr,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      btnSearch: false,
      filters: {
        course: "",
        course_sale: "",
        name: "",
        modality: "",
        display_panel: "",
        status: "",
        unit: "",
        shift: "",
        rangeDate: "",
        id: "",
      },
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      tableColumns: [
        {
          key: "id",
          label: "ID",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "name",
          label: "Código",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "start_time",
          label: "Horário",
          sortable: false,
          class: "text-elipse-400 text-center",
        },
        {
          key: "title",
          label: "Curso",
          sortable: false,
          class: "text-elipse-400",
        },
        {
          key: "modality_name",
          label: "Modalidade",
          sortable: false,
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "customers",
          label: "Matrículas",
          sortable: false,
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "postponement",
          label: "Início",
          sortable: false,
          class: "text-center",
          thStyle: "width: 110px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      optionsModalities: [],
      optionsShifts: [],
      optionsUnits: [],
      optionsCourses: [],
      optionsCourseSales: [],
    };
  },
  methods: {
    async fetchCourseSale(term) {
      this.optionsCourseSales = await this.$store.dispatch(
        "CourseSale/forSelect",
        term
      );
    },
    async fetchCourse(term) {
      this.optionsCourses = [];
      if (term.length > 2) {
        this.optionsCourses = await this.$store.dispatch(
          "Course/fetchSearch",
          term
        );
      }
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },

    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },

    async getData() {
      this.$store
        .dispatch("Classroom/all", {
          ...this.filters,
          page: this.currentPage,
          limit: this.perPage,
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar a turma caso não esteja vinculada a nenhuma matrícula.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Classroom/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.notifyDefault("success");
          });
        }
      });
    },
    deleteIsBlocked(id) {
      this.$swal({
        title: "Não é possível deletar",
        html: `A turma está associada a grade <strong>${id}</strong>. Delete a grade antes de deletar a turma.`,
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
      });
    },
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  async mounted() {
    this.getData();
    this.optionsModalities = await this.$store.dispatch(
      "Classroom/forSelectTypes"
    );
    this.optionsShifts = await this.$store.dispatch("Shift/forSelect");
    this.optionsUnits = await this.$store.dispatch("Unit/forSelect");

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
.text-elipse-400 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
</style>
